.tages-special {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 30px;
  * {
    z-index: 999;
    position: relative;
  }

  h2 {
    font-family: "Yanone Kaffeesatz";
    font-size: 80px;
    font-weight: 300;
    text-shadow: 1px 1px #ffffff;
  }

  h3 {
    font-family: "Yanone Kaffeesatz";
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 1px #ffffff;
  }

  h4 {
    font-family: "Yanone Kaffeesatz";
    font-size: 250px;
    font-weight: 200;
    text-transform: uppercase;
    color: #fff5e9;
    text-align: center;
    width: 100%;
    position: absolute;
    text-shadow: -1px -1px rgba(29,29,29,0.5);
  }


  .picture {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 150px;
    will-change: transform;
    picture {
      display: flex;
      justify-content: center;
      img {
        width: 1000px;
      }
    }
  }

  .container {
    text-align: center;
  }
}