.kalendar {
  .monat {
    margin-bottom: 60px;
    margin-top: 40px;
    .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0px;
      margin: 0px;
      .container-fluid {
        margin: 0px;
        padding: 0px;
      }
      //.monat-list {
        .headline:hover {
          background-color: #fff5e9;
        }
        .headline {
          padding-bottom: 15px;
          border-bottom: 1px solid #e5e5e5;
          transition: background-color 0.6s;
          will-change: contents;
          div {
            margin-right: 10px;
          }
          .datum {
            font-size: 20px;
            color: #a87f66;
            font-weight: bold;
          }
          .headline {
            font-size: 24px;
            color: #402a1a;
            font-weight: 600;
            border: 0px !important;
            .text {
              font-weight: 200;
              font-size: 20px;
              p {
                margin-bottom: 0px;
              }
            }
          }
          .ort {
            font-size: 20px;
            margin-right: 0px;
            padding-right: 0px;
          }
          .zeit {
            font-size: 20px;
            color: #a87f66;
            font-weight: 400;
          }
        //}
      }

      .monat-kalendar {
        flex-grow: 1;
        .kalender-monat {
          background: #ffffff;
          padding: 0px 10px 10px 10px;
          box-shadow: 4px 9px 15px #9a9a9a80;
          margin-bottom: 30px;
          .monatsausgabe {
            text-align: center;
            font-size: 32px;
            color: #00315e;
          }
          .wday {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .day {
              background: gray;
              margin: 0px 1px;
              text-align: center;
              color: #fff;
              width: 100%;
              font-weight: bold;
            }
            .ferien, .ferienToday {
              background: #aaa999 !important;
              color: #fff !important;
            }
            .ferienToday {
              font-weight: bold;
            }
            .today {
              font-weight: bold;
            }
            .cal-day {
              background: #e1e1e1;
              margin: 1px 1px;
              text-align: center;
              color: #666;
              width: 100%;
            }
            .hasTermin {
              background: #547fa6 !important;
              color: #fff !important;
            }
            .cal-space {
              width: 100%;
              margin: 1px 1px;
            }
          }
        }
      }
    }
  }
}