.etiketten-modul {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  picture {
    position: relative;
    max-width: 100%;
    img {
      width: 185px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .etiketten-modul {
    flex-wrap: wrap;
    picture {
      width: calc(100% / 3);
      img {
        width: 100%;
        max-width: 185px;
      }
    }
  }
}