@include media-breakpoint-down(lg) {
  body {
    .logo-brauhaus {
      picture {
        img {
          max-height: 90px;
        }
      }
    }
    .header {
      bottom: unset;
      top: 0px !important;
      max-height: 80px !important;
    }
    .navbar {
      .socialmediaicons {
        position: fixed;
        top: 27px !important;
        i {
          color: #fff !important;
        }
      }
      .navbar-toggler {
        top: 35px !important;
        border-color: #fff;
      }
    }
    .navbar-light {
      .navbar-toggler {
        color: #fff !important;
      }
      .navbar-toggler-icon {
        background: $navbar-dark-toggler-icon-bg;
      }
    }

    .bilder--galerie {
      .bilder--slider {
        height: calc(100vw / 16 * 8);
      }
    }
  }
}