.kartenkategorie {
  width: 100%;
  margin-top: 40px;
}

.karten-childrens {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  article {
    position: relative;
    display: block;
    width: calc(50% - 30px);
    padding-left: 10px;
    margin-right: 30px;
    margin-bottom: 20px;
    padding-top: 10px;
    float: left;
    border-top: 1px solid #cccccc;
    .title {
      width: calc(100% - 150px);
      font-weight: 600;
      float: left;
    }
    .title-halb {
      width: calc(100% - 300px);
    }
    .priceinfo {
      width: 150px;
      padding-left: 0px;
      float: left;
      display: flex;
      flex-direction: row;
      div {
        width: 50%;
        float: left;
      }
      .preis {
        font-weight: 600;
        text-align: right;
      }
      .einheit {
        font-size: 10px;
        text-align: right;
        padding-right: 5px;
      }
    }
    .inhalt {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .headline {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
  }
}

@include media-breakpoint-down(lg) {
  .kartenkategorie {
    h2 {
      font-size: 30px
    }
  }
  .karten-childrens {
    article {
      margin-bottom: 40px !important;
      .title {
        width: 100% !important;
      }
      .priceinfo {
        display: flex;
        float: unset;
        position: absolute;
        bottom: -20px;
        right: 0px;
      }
      .priceinfo2 {
        right: 150px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .karten-childrens {
    article {
      width: 100%;
      .table {
        .priceinfo {
          width: 120px;
        }
      }
    }
  }
}