/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-condensed-v25-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v25-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v25-latin-300.woff2") format("woff2"), url("../fonts/roboto-condensed-v25-latin-300.woff") format("woff"), url("../fonts/roboto-condensed-v25-latin-300.ttf") format("truetype"), url("../fonts/roboto-condensed-v25-latin-300.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-condensed-v25-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v25-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v25-latin-regular.woff2") format("woff2"), url("../fonts/roboto-condensed-v25-latin-regular.woff") format("woff"), url("../fonts/roboto-condensed-v25-latin-regular.ttf") format("truetype"), url("../fonts/roboto-condensed-v25-latin-regular.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-condensed-v25-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v25-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v25-latin-700.woff2") format("woff2"), url("../fonts/roboto-condensed-v25-latin-700.woff") format("woff"), url("../fonts/roboto-condensed-v25-latin-700.ttf") format("truetype"), url("../fonts/roboto-condensed-v25-latin-700.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* yanone-kaffeesatz-200 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/yanone-kaffeesatz-v23-latin-200.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/yanone-kaffeesatz-v23-latin-200.eot?#iefix") format("embedded-opentype"), url("../fonts/yanone-kaffeesatz-v23-latin-200.woff2") format("woff2"), url("../fonts/yanone-kaffeesatz-v23-latin-200.woff") format("woff"), url("../fonts/yanone-kaffeesatz-v23-latin-200.ttf") format("truetype"), url("../fonts/yanone-kaffeesatz-v23-latin-200.svg#YanoneKaffeesatz") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* yanone-kaffeesatz-300 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/yanone-kaffeesatz-v23-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/yanone-kaffeesatz-v23-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/yanone-kaffeesatz-v23-latin-300.woff2") format("woff2"), url("../fonts/yanone-kaffeesatz-v23-latin-300.woff") format("woff"), url("../fonts/yanone-kaffeesatz-v23-latin-300.ttf") format("truetype"), url("../fonts/yanone-kaffeesatz-v23-latin-300.svg#YanoneKaffeesatz") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* yanone-kaffeesatz-regular - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/yanone-kaffeesatz-v23-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/yanone-kaffeesatz-v23-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/yanone-kaffeesatz-v23-latin-regular.woff2") format("woff2"), url("../fonts/yanone-kaffeesatz-v23-latin-regular.woff") format("woff"), url("../fonts/yanone-kaffeesatz-v23-latin-regular.ttf") format("truetype"), url("../fonts/yanone-kaffeesatz-v23-latin-regular.svg#YanoneKaffeesatz") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* yanone-kaffeesatz-700 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/yanone-kaffeesatz-v23-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/yanone-kaffeesatz-v23-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/yanone-kaffeesatz-v23-latin-700.woff2") format("woff2"), url("../fonts/yanone-kaffeesatz-v23-latin-700.woff") format("woff"), url("../fonts/yanone-kaffeesatz-v23-latin-700.ttf") format("truetype"), url("../fonts/yanone-kaffeesatz-v23-latin-700.svg#YanoneKaffeesatz") format("svg");
  /* Legacy iOS */
  font-display: swap; }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --secondary: #ffffff;
  --primary: #a87f66;
  --primary-dark: #301610;
  --primary-light: #ffffff;
  --primary-light-hover: #60a900;
  --btn-primary-text-hover: #fff;
  --btn-primary-text: #212529;
  --btn-primary-bg: #ffa401;
  --body-bg-hover: #e0e0e0;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto Condensed";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #666666;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #666666; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #666666;
  background-color: rgba(0, 0, 0, 0.075); }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white; }

.table-hover .table-secondary:hover {
  background-color: #f2f2f2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e7dbd4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #d2bcaf; }

.table-hover .table-primary:hover {
  background-color: #decdc4; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #decdc4; }

.table-primary-dark,
.table-primary-dark > th,
.table-primary-dark > td {
  background-color: #c5bebc; }

.table-primary-dark th,
.table-primary-dark td,
.table-primary-dark thead th,
.table-primary-dark tbody + tbody {
  border-color: #938683; }

.table-hover .table-primary-dark:hover {
  background-color: #b9b1ae; }
  .table-hover .table-primary-dark:hover > td,
  .table-hover .table-primary-dark:hover > th {
    background-color: #b9b1ae; }

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: white; }

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: white; }

.table-hover .table-primary-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-primary-light:hover > td,
  .table-hover .table-primary-light:hover > th {
    background-color: #f2f2f2; }

.table-primary-light-hover,
.table-primary-light-hover > th,
.table-primary-light-hover > td {
  background-color: #d2e7b8; }

.table-primary-light-hover th,
.table-primary-light-hover td,
.table-primary-light-hover thead th,
.table-primary-light-hover tbody + tbody {
  border-color: #acd27a; }

.table-hover .table-primary-light-hover:hover {
  background-color: #c6e1a5; }
  .table-hover .table-primary-light-hover:hover > td,
  .table-hover .table-primary-light-hover:hover > th {
    background-color: #c6e1a5; }

.table-btn-primary-text-hover,
.table-btn-primary-text-hover > th,
.table-btn-primary-text-hover > td {
  background-color: white; }

.table-btn-primary-text-hover th,
.table-btn-primary-text-hover td,
.table-btn-primary-text-hover thead th,
.table-btn-primary-text-hover tbody + tbody {
  border-color: white; }

.table-hover .table-btn-primary-text-hover:hover {
  background-color: #f2f2f2; }
  .table-hover .table-btn-primary-text-hover:hover > td,
  .table-hover .table-btn-primary-text-hover:hover > th {
    background-color: #f2f2f2; }

.table-btn-primary-text,
.table-btn-primary-text > th,
.table-btn-primary-text > td {
  background-color: #c1c2c3; }

.table-btn-primary-text th,
.table-btn-primary-text td,
.table-btn-primary-text thead th,
.table-btn-primary-text tbody + tbody {
  border-color: #8c8e90; }

.table-hover .table-btn-primary-text:hover {
  background-color: #b4b5b6; }
  .table-hover .table-btn-primary-text:hover > td,
  .table-hover .table-btn-primary-text:hover > th {
    background-color: #b4b5b6; }

.table-btn-primary-bg,
.table-btn-primary-bg > th,
.table-btn-primary-bg > td {
  background-color: #ffe6b8; }

.table-btn-primary-bg th,
.table-btn-primary-bg td,
.table-btn-primary-bg thead th,
.table-btn-primary-bg tbody + tbody {
  border-color: #ffd07b; }

.table-hover .table-btn-primary-bg:hover {
  background-color: #ffdd9f; }
  .table-hover .table-btn-primary-bg:hover > td,
  .table-hover .table-btn-primary-bg:hover > th {
    background-color: #ffdd9f; }

.table-body-bg-hover,
.table-body-bg-hover > th,
.table-body-bg-hover > td {
  background-color: #f6f6f6; }

.table-body-bg-hover th,
.table-body-bg-hover td,
.table-body-bg-hover thead th,
.table-body-bg-hover tbody + tbody {
  border-color: #efefef; }

.table-hover .table-body-bg-hover:hover {
  background-color: #e9e9e9; }
  .table-hover .table-body-bg-hover:hover > td,
  .table-hover .table-body-bg-hover:hover > th {
    background-color: #e9e9e9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #666666;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #666666;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-secondary {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-primary {
  color: #fff;
  background-color: #a87f66;
  border-color: #a87f66; }
  .btn-primary:hover {
    color: #fff;
    background-color: #946c54;
    border-color: #8c664f; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #946c54;
    border-color: #8c664f;
    box-shadow: 0 0 0 0.2rem rgba(181, 146, 125, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #a87f66;
    border-color: #a87f66; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #8c664f;
    border-color: #83604b; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(181, 146, 125, 0.5); }

.btn-primary-dark {
  color: #fff;
  background-color: #301610;
  border-color: #301610; }
  .btn-primary-dark:hover {
    color: #fff;
    background-color: #130906;
    border-color: #0a0403; }
  .btn-primary-dark:focus, .btn-primary-dark.focus {
    color: #fff;
    background-color: #130906;
    border-color: #0a0403;
    box-shadow: 0 0 0 0.2rem rgba(79, 57, 52, 0.5); }
  .btn-primary-dark.disabled, .btn-primary-dark:disabled {
    color: #fff;
    background-color: #301610;
    border-color: #301610; }
  .btn-primary-dark:not(:disabled):not(.disabled):active, .btn-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #0a0403;
    border-color: black; }
    .btn-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-primary-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(79, 57, 52, 0.5); }

.btn-primary-light {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-primary-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-primary-light:focus, .btn-primary-light.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-primary-light-hover {
  color: #fff;
  background-color: #60a900;
  border-color: #60a900; }
  .btn-primary-light-hover:hover {
    color: #fff;
    background-color: #4a8300;
    border-color: #437600; }
  .btn-primary-light-hover:focus, .btn-primary-light-hover.focus {
    color: #fff;
    background-color: #4a8300;
    border-color: #437600;
    box-shadow: 0 0 0 0.2rem rgba(120, 182, 38, 0.5); }
  .btn-primary-light-hover.disabled, .btn-primary-light-hover:disabled {
    color: #fff;
    background-color: #60a900;
    border-color: #60a900; }
  .btn-primary-light-hover:not(:disabled):not(.disabled):active, .btn-primary-light-hover:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light-hover.dropdown-toggle {
    color: #fff;
    background-color: #437600;
    border-color: #3c6900; }
    .btn-primary-light-hover:not(:disabled):not(.disabled):active:focus, .btn-primary-light-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(120, 182, 38, 0.5); }

.btn-btn-primary-text-hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-btn-primary-text-hover:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-btn-primary-text-hover:focus, .btn-btn-primary-text-hover.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-btn-primary-text-hover.disabled, .btn-btn-primary-text-hover:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-btn-primary-text-hover:not(:disabled):not(.disabled):active, .btn-btn-primary-text-hover:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-text-hover.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-btn-primary-text-hover:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-text-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-text-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-btn-primary-text {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-btn-primary-text:hover {
    color: #fff;
    background-color: #101214;
    border-color: #0a0c0d; }
  .btn-btn-primary-text:focus, .btn-btn-primary-text.focus {
    color: #fff;
    background-color: #101214;
    border-color: #0a0c0d;
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-btn-primary-text.disabled, .btn-btn-primary-text:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-btn-primary-text:not(:disabled):not(.disabled):active, .btn-btn-primary-text:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-text.dropdown-toggle {
    color: #fff;
    background-color: #0a0c0d;
    border-color: #050506; }
    .btn-btn-primary-text:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }

.btn-btn-primary-bg {
  color: #212529;
  background-color: #ffa401;
  border-color: #ffa401; }
  .btn-btn-primary-bg:hover {
    color: #fff;
    background-color: #da8c00;
    border-color: #cd8400; }
  .btn-btn-primary-bg:focus, .btn-btn-primary-bg.focus {
    color: #fff;
    background-color: #da8c00;
    border-color: #cd8400;
    box-shadow: 0 0 0 0.2rem rgba(222, 145, 7, 0.5); }
  .btn-btn-primary-bg.disabled, .btn-btn-primary-bg:disabled {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
  .btn-btn-primary-bg:not(:disabled):not(.disabled):active, .btn-btn-primary-bg:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-bg.dropdown-toggle {
    color: #fff;
    background-color: #cd8400;
    border-color: #c07b00; }
    .btn-btn-primary-bg:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 145, 7, 0.5); }

.btn-body-bg-hover {
  color: #212529;
  background-color: #e0e0e0;
  border-color: #e0e0e0; }
  .btn-body-bg-hover:hover {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c7c7; }
  .btn-body-bg-hover:focus, .btn-body-bg-hover.focus {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c7c7;
    box-shadow: 0 0 0 0.2rem rgba(195, 196, 197, 0.5); }
  .btn-body-bg-hover.disabled, .btn-body-bg-hover:disabled {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-body-bg-hover:not(:disabled):not(.disabled):active, .btn-body-bg-hover:not(:disabled):not(.disabled).active,
  .show > .btn-body-bg-hover.dropdown-toggle {
    color: #212529;
    background-color: #c7c7c7;
    border-color: silver; }
    .btn-body-bg-hover:not(:disabled):not(.disabled):active:focus, .btn-body-bg-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-body-bg-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(195, 196, 197, 0.5); }

.btn-outline-secondary {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-primary {
  color: #a87f66;
  border-color: #a87f66; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #a87f66;
    border-color: #a87f66; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 127, 102, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #a87f66;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #a87f66;
    border-color: #a87f66; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 127, 102, 0.5); }

.btn-outline-primary-dark {
  color: #301610;
  border-color: #301610; }
  .btn-outline-primary-dark:hover {
    color: #fff;
    background-color: #301610;
    border-color: #301610; }
  .btn-outline-primary-dark:focus, .btn-outline-primary-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(48, 22, 16, 0.5); }
  .btn-outline-primary-dark.disabled, .btn-outline-primary-dark:disabled {
    color: #301610;
    background-color: transparent; }
  .btn-outline-primary-dark:not(:disabled):not(.disabled):active, .btn-outline-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #301610;
    border-color: #301610; }
    .btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 22, 16, 0.5); }

.btn-outline-primary-light {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-primary-light:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-primary-light-hover {
  color: #60a900;
  border-color: #60a900; }
  .btn-outline-primary-light-hover:hover {
    color: #fff;
    background-color: #60a900;
    border-color: #60a900; }
  .btn-outline-primary-light-hover:focus, .btn-outline-primary-light-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 169, 0, 0.5); }
  .btn-outline-primary-light-hover.disabled, .btn-outline-primary-light-hover:disabled {
    color: #60a900;
    background-color: transparent; }
  .btn-outline-primary-light-hover:not(:disabled):not(.disabled):active, .btn-outline-primary-light-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light-hover.dropdown-toggle {
    color: #fff;
    background-color: #60a900;
    border-color: #60a900; }
    .btn-outline-primary-light-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 169, 0, 0.5); }

.btn-outline-btn-primary-text-hover {
  color: #fff;
  border-color: #fff; }
  .btn-outline-btn-primary-text-hover:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-btn-primary-text-hover:focus, .btn-outline-btn-primary-text-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-btn-primary-text-hover.disabled, .btn-outline-btn-primary-text-hover:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-text-hover.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-text-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-btn-primary-text {
  color: #212529;
  border-color: #212529; }
  .btn-outline-btn-primary-text:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-outline-btn-primary-text:focus, .btn-outline-btn-primary-text.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }
  .btn-outline-btn-primary-text.disabled, .btn-outline-btn-primary-text:disabled {
    color: #212529;
    background-color: transparent; }
  .btn-outline-btn-primary-text:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-text:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-text.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-btn-primary-text:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.btn-outline-btn-primary-bg {
  color: #ffa401;
  border-color: #ffa401; }
  .btn-outline-btn-primary-bg:hover {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
  .btn-outline-btn-primary-bg:focus, .btn-outline-btn-primary-bg.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 164, 1, 0.5); }
  .btn-outline-btn-primary-bg.disabled, .btn-outline-btn-primary-bg:disabled {
    color: #ffa401;
    background-color: transparent; }
  .btn-outline-btn-primary-bg:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-bg:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-bg.dropdown-toggle {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
    .btn-outline-btn-primary-bg:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 164, 1, 0.5); }

.btn-outline-body-bg-hover {
  color: #e0e0e0;
  border-color: #e0e0e0; }
  .btn-outline-body-bg-hover:hover {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-outline-body-bg-hover:focus, .btn-outline-body-bg-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }
  .btn-outline-body-bg-hover.disabled, .btn-outline-body-bg-hover:disabled {
    color: #e0e0e0;
    background-color: transparent; }
  .btn-outline-body-bg-hover:not(:disabled):not(.disabled):active, .btn-outline-body-bg-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-body-bg-hover.dropdown-toggle {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
    .btn-outline-body-bg-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-body-bg-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-body-bg-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #666666;
  text-align: left;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #ffffff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: none; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #ffffff; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #666666;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-secondary {
  color: #858585;
  background-color: white; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-primary {
  color: #574235;
  background-color: #e7dbd4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #574235;
    background-color: #decdc4; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #574235;
    border-color: #574235; }

.list-group-item-primary-dark {
  color: #190b08;
  background-color: #c5bebc; }
  .list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
    color: #190b08;
    background-color: #b9b1ae; }
  .list-group-item-primary-dark.list-group-item-action.active {
    color: #fff;
    background-color: #190b08;
    border-color: #190b08; }

.list-group-item-primary-light {
  color: #858585;
  background-color: white; }
  .list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-primary-light.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-primary-light-hover {
  color: #325800;
  background-color: #d2e7b8; }
  .list-group-item-primary-light-hover.list-group-item-action:hover, .list-group-item-primary-light-hover.list-group-item-action:focus {
    color: #325800;
    background-color: #c6e1a5; }
  .list-group-item-primary-light-hover.list-group-item-action.active {
    color: #fff;
    background-color: #325800;
    border-color: #325800; }

.list-group-item-btn-primary-text-hover {
  color: #858585;
  background-color: white; }
  .list-group-item-btn-primary-text-hover.list-group-item-action:hover, .list-group-item-btn-primary-text-hover.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-btn-primary-text-hover.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-btn-primary-text {
  color: #111315;
  background-color: #c1c2c3; }
  .list-group-item-btn-primary-text.list-group-item-action:hover, .list-group-item-btn-primary-text.list-group-item-action:focus {
    color: #111315;
    background-color: #b4b5b6; }
  .list-group-item-btn-primary-text.list-group-item-action.active {
    color: #fff;
    background-color: #111315;
    border-color: #111315; }

.list-group-item-btn-primary-bg {
  color: #855501;
  background-color: #ffe6b8; }
  .list-group-item-btn-primary-bg.list-group-item-action:hover, .list-group-item-btn-primary-bg.list-group-item-action:focus {
    color: #855501;
    background-color: #ffdd9f; }
  .list-group-item-btn-primary-bg.list-group-item-action.active {
    color: #fff;
    background-color: #855501;
    border-color: #855501; }

.list-group-item-body-bg-hover {
  color: #747474;
  background-color: #f6f6f6; }
  .list-group-item-body-bg-hover.list-group-item-action:hover, .list-group-item-body-bg-hover.list-group-item-action:focus {
    color: #747474;
    background-color: #e9e9e9; }
  .list-group-item-body-bg-hover.list-group-item-action.active {
    color: #fff;
    background-color: #747474;
    border-color: #747474; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-secondary {
  background-color: #ffffff !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important; }

.bg-primary {
  background-color: #a87f66 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #8c664f !important; }

.bg-primary-dark {
  background-color: #301610 !important; }

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #0a0403 !important; }

.bg-primary-light {
  background-color: #ffffff !important; }

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #e6e6e6 !important; }

.bg-primary-light-hover {
  background-color: #60a900 !important; }

a.bg-primary-light-hover:hover, a.bg-primary-light-hover:focus,
button.bg-primary-light-hover:hover,
button.bg-primary-light-hover:focus {
  background-color: #437600 !important; }

.bg-btn-primary-text-hover {
  background-color: #fff !important; }

a.bg-btn-primary-text-hover:hover, a.bg-btn-primary-text-hover:focus,
button.bg-btn-primary-text-hover:hover,
button.bg-btn-primary-text-hover:focus {
  background-color: #e6e6e6 !important; }

.bg-btn-primary-text {
  background-color: #212529 !important; }

a.bg-btn-primary-text:hover, a.bg-btn-primary-text:focus,
button.bg-btn-primary-text:hover,
button.bg-btn-primary-text:focus {
  background-color: #0a0c0d !important; }

.bg-btn-primary-bg {
  background-color: #ffa401 !important; }

a.bg-btn-primary-bg:hover, a.bg-btn-primary-bg:focus,
button.bg-btn-primary-bg:hover,
button.bg-btn-primary-bg:focus {
  background-color: #cd8400 !important; }

.bg-body-bg-hover {
  background-color: #e0e0e0 !important; }

a.bg-body-bg-hover:hover, a.bg-body-bg-hover:focus,
button.bg-body-bg-hover:hover,
button.bg-body-bg-hover:focus {
  background-color: #c7c7c7 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-secondary {
  border-color: #ffffff !important; }

.border-primary {
  border-color: #a87f66 !important; }

.border-primary-dark {
  border-color: #301610 !important; }

.border-primary-light {
  border-color: #ffffff !important; }

.border-primary-light-hover {
  border-color: #60a900 !important; }

.border-btn-primary-text-hover {
  border-color: #fff !important; }

.border-btn-primary-text {
  border-color: #212529 !important; }

.border-btn-primary-bg {
  border-color: #ffa401 !important; }

.border-body-bg-hover {
  border-color: #e0e0e0 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-secondary {
  color: #ffffff !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important; }

.text-primary {
  color: #a87f66 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #7b5a46 !important; }

.text-primary-dark {
  color: #301610 !important; }

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: black !important; }

.text-primary-light {
  color: #ffffff !important; }

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #d9d9d9 !important; }

.text-primary-light-hover {
  color: #60a900 !important; }

a.text-primary-light-hover:hover, a.text-primary-light-hover:focus {
  color: #355d00 !important; }

.text-btn-primary-text-hover {
  color: #fff !important; }

a.text-btn-primary-text-hover:hover, a.text-btn-primary-text-hover:focus {
  color: #d9d9d9 !important; }

.text-btn-primary-text {
  color: #212529 !important; }

a.text-btn-primary-text:hover, a.text-btn-primary-text:focus {
  color: black !important; }

.text-btn-primary-bg {
  color: #ffa401 !important; }

a.text-btn-primary-bg:hover, a.text-btn-primary-bg:focus {
  color: #b47300 !important; }

.text-body-bg-hover {
  color: #e0e0e0 !important; }

a.text-body-bg-hover:hover, a.text-body-bg-hover:focus {
  color: #bababa !important; }

.text-body {
  color: #666666 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

header .header--shadow {
  position: absolute;
  top: 126px; }

.navbar {
  padding: 15px;
  width: 100%;
  height: 80px;
  background-color: #fff;
  top: 160px;
  box-shadow: 0px -5px 20px 10px #000; }
  .navbar .navbar-toggler-icon {
    background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important; }
  .navbar .socialmediaicons {
    position: absolute;
    top: -165px;
    left: 0px;
    padding: 10px;
    z-index: 9999;
    color: #a87f66;
    transition: all 0.3s; }
    .navbar .socialmediaicons i {
      color: #a87f66;
      transition: color 0.3s; }
      .navbar .socialmediaicons i svg {
        height: 25px; }
      .navbar .socialmediaicons i img {
        height: 25px; }
  .navbar .text-light i {
    color: #ffffff !important; }
  .navbar .socialmediaicons-scrolled {
    color: #ffffff;
    position: absolute;
    top: -50px; }
  .navbar .btn {
    margin-left: 10px;
    background: #a87f66;
    color: #ccc;
    border: 0px; }
  .navbar .btn-secondary:hover {
    background: #301610; }
  .navbar .logo {
    height: 97px;
    position: absolute;
    top: 15px;
    left: 20px; }
  .navbar .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    font-size: 35px;
    font-family: "Yanone Kaffeesatz";
    text-transform: uppercase;
    width: 100%;
    padding: 0px 100px;
    justify-content: space-between;
    height: 80px; }
    .navbar .navbar-nav .nav-item {
      display: flex;
      justify-content: center;
      position: relative;
      flex: 0 0 1px; }
      .navbar .navbar-nav .nav-item a {
        color: #ffffff;
        display: flex;
        align-items: center;
        text-shadow: -0.5px -0.5px #ccc;
        font-family: "Yanone Kaffeesatz";
        font-weight: 600;
        transition: color 0.3s; }
      .navbar .navbar-nav .nav-item > a:hover {
        opacity: 0.5;
        color: #fff !important; }
      .navbar .navbar-nav .nav-item .dropdown-toggle::after {
        content: unset; }
    .navbar .navbar-nav .dropdown-toggle::after {
      content: unset; }
    .navbar .navbar-nav .active {
      color: rgba(255, 255, 255, 0.5) !important; }
      .navbar .navbar-nav .active a {
        color: rgba(255, 255, 255, 0.5) !important; }
    .navbar .navbar-nav .submenue {
      display: flex;
      flex-direction: column;
      font-size: 20px; }
      .navbar .navbar-nav .submenue .dropdown-item {
        background: none;
        font-size: 30px; }
  .navbar .dropdown-menu {
    left: unset !important;
    top: 80px;
    display: none;
    flex-direction: column;
    border: 0px;
    box-shadow: 0px 0px 5px #999;
    width: 100%;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.9); }
    .navbar .dropdown-menu .dropdown-item {
      font-size: 30px;
      text-align: center;
      text-transform: none;
      font-weight: 600;
      justify-content: center;
      text-shadow: 1px 1px #ffffff !important; }
    .navbar .dropdown-menu .submenue-second {
      font-size: 20px;
      padding: 0px 80px; }
  .navbar .show {
    display: flex; }
    .navbar .show > .nav-link {
      color: #fff !important; }
  .navbar .navbar-toggler {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 999999; }

.navbar-dark .navbar-toggler {
  color: #ffffff;
  border-color: #ffffff; }

.teaser {
  width: 100%;
  height: calc(100vh / 1.2);
  max-height: 770px;
  min-height: 500px;
  position: relative;
  overflow: hidden;
  margin-top: -18px; }
  .teaser .teaser--bg {
    width: 100%;
    height: 150%;
    position: absolute;
    opacity: 0;
    transition: opacity 0.6s; }
    .teaser .teaser--bg img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .teaser .active {
    opacity: 1; }
  .teaser .teaser--shadow {
    position: absolute;
    bottom: -10px;
    width: 100vw;
    height: 0px;
    box-shadow: 0px -10px 30px 10px #000000;
    transform: rotate(-0.5deg);
    z-index: 999; }
  .teaser article {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100vw;
    padding-left: 180px;
    padding-right: 180px; }
    .teaser article h1 {
      font-size: 90px;
      color: #ccc;
      font-weight: 600;
      text-align: left;
      border-bottom: 0px !important;
      font-family: "Yanone Kaffeesatz";
      mix-blend-mode: color-dodge;
      opacity: 0.5; }
    .teaser article p {
      font-size: 34px;
      color: #ccc;
      opacity: 1;
      text-align: left;
      font-family: 'Roboto Condensed';
      mix-blend-mode: color-dodge;
      opacity: 0.5; }
    .teaser article .embed-responsive {
      box-shadow: 0px 0px 15px #000;
      background: #a87f66;
      border-radius: 15px; }
    .teaser article .fade-in {
      opacity: 0; }
  .teaser .fade-rtl, .teaser .fade-ltr {
    transform: matrix(1, 0, 0, 1, 0, 0);
    transition: all, 0s, 0s;
    transition-duration: 3s, 3s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out, linear;
    will-change: opacity, transform;
    opacity: 0; }
  .teaser .fade-rtl {
    transform: translate(50px); }
  .teaser .fade-ltr {
    transform: translate(-50px); }
  .teaser #conversionbuttonindex2 {
    position: absolute;
    right: 20%;
    bottom: 35%;
    opacity: 0;
    font-size: 35px;
    box-shadow: 0px 0px 15px #333;
    padding: 10px 20px 10px 20px;
    transition: all 0.5s ease; }

.has-open, .has-closed {
  position: absolute;
  z-index: 999;
  height: auto;
  transform: translate(-50%, -80px);
  left: 50%;
  background: #ffffff;
  width: 580px;
  display: flex;
  height: 80px;
  font-family: 'Yanone Kaffeesatz'; }
  .has-open .clock, .has-closed .clock {
    width: 85px;
    height: 60px;
    margin-top: 10px;
    border-right: 1px solid #cdc0b0;
    margin-right: 15px; }
    .has-open .clock i, .has-closed .clock i {
      font-size: 60px;
      color: #cdc0b0; }
      .has-open .clock i svg, .has-open .clock i img, .has-closed .clock i svg, .has-closed .clock i img {
        height: 60px;
        transform: translate(10px, -15px); }
  .has-open .text, .has-closed .text {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .text-closed, .has-closed .text-closed {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .zeit, .has-closed .zeit {
    position: relative;
    width: 165px;
    margin-left: 20px;
    margin-top: 10px;
    font-weight: bold;
    font-size: 35px;
    color: #cdc0b0; }

.teaser--shadow {
  width: 100%;
  height: 10px;
  background: transparent;
  transform: translateY(0px);
  z-index: 1001;
  position: relative;
  box-shadow: 0px -7px 10px rgba(0, 0, 0, 0.5) !important; }

.teaser--main {
  overflow: hidden;
  position: relative; }

.has-closed {
  width: 450px; }

.teaser--halb .teaser {
  width: 100%;
  height: calc(100vh / 1.2);
  max-height: 400px;
  min-height: 300px;
  position: relative;
  overflow: hidden; }
  .teaser--halb .teaser .teaser--bg {
    width: 100%;
    height: 250%;
    position: absolute;
    opacity: 0;
    transition: opacity 0.6s; }
    .teaser--halb .teaser .teaser--bg img {
      height: auto; }
  .teaser--halb .teaser .active {
    opacity: 1; }
  .teaser--halb .teaser .teaser--shadow {
    position: absolute;
    bottom: -10px;
    width: 100vw;
    height: 0px;
    box-shadow: 0px -10px 10px #000000;
    transform: rotate(-0.5deg);
    z-index: 999; }
  .teaser--halb .teaser article {
    position: absolute;
    top: 30%;
    left: 0%;
    width: 100vw;
    padding-left: 180px;
    padding-right: 180px; }
    .teaser--halb .teaser article h1 {
      font-size: 90px;
      color: #ffffff;
      font-weight: 600;
      text-align: left;
      border-bottom: 0px !important;
      font-family: "Yanone Kaffeesatz"; }
    .teaser--halb .teaser article p {
      font-size: 25px;
      text-shadow: rgba(0, 0, 0, 0.9) 1px 1px 10px;
      color: #ffffff;
      opacity: 0;
      text-align: left;
      font-family: "Roboto"; }
    .teaser--halb .teaser article .embed-responsive {
      box-shadow: 0px 0px 15px #000;
      background: #a87f66;
      border-radius: 15px; }
    .teaser--halb .teaser article .fade-in {
      opacity: 0; }
  .teaser--halb .teaser .fade-rtl, .teaser--halb .teaser .fade-ltr {
    transform: matrix(1, 0, 0, 1, 0, 0);
    transition: all, 0s, 0s;
    transition-duration: 3s, 3s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out, linear;
    will-change: opacity, transform;
    opacity: 0; }
  .teaser--halb .teaser .fade-rtl {
    transform: translate(50px); }
  .teaser--halb .teaser .fade-ltr {
    transform: translate(-50px); }
  .teaser--halb .teaser #conversionbuttonindex2 {
    position: absolute;
    right: 20%;
    bottom: 35%;
    opacity: 0;
    font-size: 35px;
    box-shadow: 0px 0px 15px #333;
    padding: 10px 20px 10px 20px;
    transition: all 0.5s ease; }

@media (max-width: 767.98px) {
  .teaser--halb .teaser {
    max-height: 110px;
    min-height: 100px; }
  .clock i svg, .clock i img {
    height: 30px !important;
    transform: translate(10px, -9px) !important; } }

.has-open, .has-closed {
  position: absolute;
  z-index: 999;
  height: auto;
  transform: translate(-50%, -80px);
  left: 50%;
  background: #ffffff;
  width: 580px;
  display: flex;
  height: 80px;
  font-family: 'Yanone Kaffeesatz'; }
  .has-open .clock, .has-closed .clock {
    width: 85px;
    height: 60px;
    margin-top: 10px;
    border-right: 1px solid #cdc0b0;
    margin-right: 15px; }
    .has-open .clock i, .has-closed .clock i {
      font-size: 60px;
      color: #cdc0b0; }
      .has-open .clock i svg, .has-open .clock i img, .has-closed .clock i svg, .has-closed .clock i img {
        height: 60px;
        transform: translate(10px, -15px); }
  .has-open .text, .has-closed .text {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .text-closed, .has-closed .text-closed {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .zeit, .has-closed .zeit {
    position: relative;
    width: 165px;
    margin-left: 20px;
    margin-top: 10px;
    font-weight: bold;
    font-size: 35px;
    color: #cdc0b0; }

.teaser--shadow {
  width: 100%;
  height: 10px;
  background: transparent;
  transform: translateY(0px);
  z-index: 1001;
  position: relative;
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.5) !important; }

.teaser--main {
  overflow: hidden;
  position: relative; }

.has-closed {
  width: 450px; }

.kartenkategorie {
  width: 100%;
  margin-top: 40px; }

.karten-childrens {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%; }
  .karten-childrens article {
    position: relative;
    display: block;
    width: calc(50% - 30px);
    padding-left: 10px;
    margin-right: 30px;
    margin-bottom: 20px;
    padding-top: 10px;
    float: left;
    border-top: 1px solid #cccccc; }
    .karten-childrens article .title {
      width: calc(100% - 150px);
      font-weight: 600;
      float: left; }
    .karten-childrens article .title-halb {
      width: calc(100% - 300px); }
    .karten-childrens article .priceinfo {
      width: 150px;
      padding-left: 0px;
      float: left;
      display: flex;
      flex-direction: row; }
      .karten-childrens article .priceinfo div {
        width: 50%;
        float: left; }
      .karten-childrens article .priceinfo .preis {
        font-weight: 600;
        text-align: right; }
      .karten-childrens article .priceinfo .einheit {
        font-size: 10px;
        text-align: right;
        padding-right: 5px; }
    .karten-childrens article .inhalt {
      padding-left: 10px;
      padding-right: 10px; }
  .karten-childrens .headline {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative; }

@media (max-width: 1199.98px) {
  .kartenkategorie h2 {
    font-size: 30px; }
  .karten-childrens article {
    margin-bottom: 40px !important; }
    .karten-childrens article .title {
      width: 100% !important; }
    .karten-childrens article .priceinfo {
      display: flex;
      float: unset;
      position: absolute;
      bottom: -20px;
      right: 0px; }
    .karten-childrens article .priceinfo2 {
      right: 150px; } }

@media (max-width: 575.98px) {
  .karten-childrens article {
    width: 100%; }
    .karten-childrens article .table .priceinfo {
      width: 120px; } }

.stephanus--flaschen {
  position: relative;
  height: 750px;
  display: flex;
  justify-content: center;
  margin-top: 50px; }
  .stephanus--flaschen picture {
    width: 758px;
    height: 750px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .stephanus--flaschen img {
    height: 700px;
    position: absolute;
    will-change: transfrom; }
  .stephanus--flaschen .flasche-1 {
    z-index: 10; }
  .stephanus--flaschen .flasche-2 {
    z-index: 9; }
  .stephanus--flaschen .flasche-3 {
    z-index: 8; }
  .stephanus--flaschen .flasche-4 {
    z-index: 7; }
  .stephanus--flaschen .horizon {
    height: unset !important;
    width: 100%;
    position: absolute;
    top: 60%; }
    .stephanus--flaschen .horizon picture {
      width: 100%; }
      .stephanus--flaschen .horizon picture img {
        width: 100%;
        height: unset; }

@media (max-width: 575.98px) {
  .stephanus--flaschen {
    height: 350px !important;
    overflow: hidden;
    width: 100vw;
    transform: translateX(-50%);
    left: 50%; }
    .stephanus--flaschen picture {
      width: 350px;
      height: 345px; }
    .stephanus--flaschen img {
      height: 350px; } }

.bilder--galerie {
  justify-content: center;
  display: flex; }
  .bilder--galerie .bilder--slider {
    width: 100%;
    height: calc(100vw / 16 * 9);
    overflow: hidden;
    position: relative; }
    .bilder--galerie .bilder--slider picture {
      opacity: 0;
      transition: opacity 0.8s;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%; }
      .bilder--galerie .bilder--slider picture img {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px; }
    .bilder--galerie .bilder--slider .active {
      opacity: 1; }
  .bilder--galerie .bilder-slider-preview {
    position: absolute;
    bottom: 70px;
    height: 83px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .bilder--galerie .bilder-slider-preview picture {
      position: relative;
      height: 72px;
      width: 120px;
      padding: 5px;
      margin: 0px 10px;
      cursor: pointer; }
      .bilder--galerie .bilder-slider-preview picture img {
        width: calc(100% - 10px);
        position: absolute;
        top: 5px;
        left: 5px;
        opacity: 1;
        transition: opacity 0.8s;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border: 3px solid #ffffff; }
    .bilder--galerie .bilder-slider-preview .active img {
      opacity: 0.5; }

.brauhaus-woche {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px; }
  .brauhaus-woche .tag {
    width: 100%;
    display: flex;
    flex-direction: column; }
    .brauhaus-woche .tag h2 {
      border-bottom: 0px;
      text-align: left;
      padding-left: 600px; }
    .brauhaus-woche .tag .inhalt {
      width: 100%;
      display: flex;
      flex-direction: row; }
      .brauhaus-woche .tag .inhalt .picture {
        margin-top: -50px;
        display: flex; }
        .brauhaus-woche .tag .inhalt .picture picture {
          width: 600px; }
          .brauhaus-woche .tag .inhalt .picture picture img {
            width: 600px; }
      .brauhaus-woche .tag .inhalt .inhalt {
        flex-direction: column; }
        .brauhaus-woche .tag .inhalt .inhalt .headline {
          font-weight: bold;
          color: #00673e;
          font-weight: bold; }

@media (max-width: 991.98px) {
  .brauhaus-woche {
    width: 100vw;
    margin-left: -15px; }
    .brauhaus-woche .tag {
      margin-bottom: 60px; }
      .brauhaus-woche .tag h2 {
        padding-left: 0px !important;
        text-align: center;
        font-size: 30px; }
      .brauhaus-woche .tag .inhalt {
        flex-direction: column;
        overflow: hidden; }
        .brauhaus-woche .tag .inhalt .inhalt {
          margin-left: 15px;
          width: calc(100vw - 30px); }
        .brauhaus-woche .tag .inhalt .picture {
          margin-top: 0px !important;
          width: 130% !important; }
          .brauhaus-woche .tag .inhalt .picture picture {
            width: 100%;
            transform: translate3d(-44px, 0px, 0px); }
            .brauhaus-woche .tag .inhalt .picture picture img {
              width: 100%; } }

.kalendar .monat {
  margin-bottom: 60px;
  margin-top: 40px; }
  .kalendar .monat .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px;
    margin: 0px; }
    .kalendar .monat .content .container-fluid, .kalendar .monat .content .container-sm, .kalendar .monat .content .container-md, .kalendar .monat .content .container-lg, .kalendar .monat .content .container-xl {
      margin: 0px;
      padding: 0px; }
    .kalendar .monat .content .headline:hover {
      background-color: #fff5e9; }
    .kalendar .monat .content .headline {
      padding-bottom: 15px;
      border-bottom: 1px solid #e5e5e5;
      transition: background-color 0.6s;
      will-change: contents; }
      .kalendar .monat .content .headline div {
        margin-right: 10px; }
      .kalendar .monat .content .headline .datum {
        font-size: 20px;
        color: #a87f66;
        font-weight: bold; }
      .kalendar .monat .content .headline .headline {
        font-size: 24px;
        color: #402a1a;
        font-weight: 600;
        border: 0px !important; }
        .kalendar .monat .content .headline .headline .text {
          font-weight: 200;
          font-size: 20px; }
          .kalendar .monat .content .headline .headline .text p {
            margin-bottom: 0px; }
      .kalendar .monat .content .headline .ort {
        font-size: 20px;
        margin-right: 0px;
        padding-right: 0px; }
      .kalendar .monat .content .headline .zeit {
        font-size: 20px;
        color: #a87f66;
        font-weight: 400; }
    .kalendar .monat .content .monat-kalendar {
      flex-grow: 1; }
      .kalendar .monat .content .monat-kalendar .kalender-monat {
        background: #ffffff;
        padding: 0px 10px 10px 10px;
        box-shadow: 4px 9px 15px #9a9a9a80;
        margin-bottom: 30px; }
        .kalendar .monat .content .monat-kalendar .kalender-monat .monatsausgabe {
          text-align: center;
          font-size: 32px;
          color: #00315e; }
        .kalendar .monat .content .monat-kalendar .kalender-monat .wday {
          display: flex;
          flex-direction: row;
          justify-content: space-around; }
          .kalendar .monat .content .monat-kalendar .kalender-monat .wday .day {
            background: gray;
            margin: 0px 1px;
            text-align: center;
            color: #fff;
            width: 100%;
            font-weight: bold; }
          .kalendar .monat .content .monat-kalendar .kalender-monat .wday .ferien, .kalendar .monat .content .monat-kalendar .kalender-monat .wday .ferienToday {
            background: #aaa999 !important;
            color: #fff !important; }
          .kalendar .monat .content .monat-kalendar .kalender-monat .wday .ferienToday {
            font-weight: bold; }
          .kalendar .monat .content .monat-kalendar .kalender-monat .wday .today {
            font-weight: bold; }
          .kalendar .monat .content .monat-kalendar .kalender-monat .wday .cal-day {
            background: #e1e1e1;
            margin: 1px 1px;
            text-align: center;
            color: #666;
            width: 100%; }
          .kalendar .monat .content .monat-kalendar .kalender-monat .wday .hasTermin {
            background: #547fa6 !important;
            color: #fff !important; }
          .kalendar .monat .content .monat-kalendar .kalender-monat .wday .cal-space {
            width: 100%;
            margin: 1px 1px; }

.kontakticons {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 70px;
  justify-content: space-around;
  margin-bottom: 30px;
  z-index: 10; }
  .kontakticons a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff5e9;
    transition: color 0.3s; }
  .kontakticons a:hover {
    color: #b2aea5 !important; }
  .kontakticons i {
    padding: 15px 30px 10px 30px;
    width: 130px;
    transform: translateX(-50%);
    position: relative;
    left: 50%; }
    .kontakticons i svg {
      height: 70px; }
    .kontakticons i img {
      height: 70px; }
  .kontakticons .text {
    font-size: 16px;
    text-align: center; }

.dunkle-icons a {
  color: #301610 !important; }

.dunkle-icons a:hover {
  color: #4d2b20 !important; }

.etiketten-modul {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px; }
  .etiketten-modul picture {
    position: relative;
    max-width: 100%; }
    .etiketten-modul picture img {
      width: 185px; }

@media (max-width: 1199.98px) {
  .etiketten-modul {
    flex-wrap: wrap; }
    .etiketten-modul picture {
      width: calc(100% / 3); }
      .etiketten-modul picture img {
        width: 100%;
        max-width: 185px; } }

::selection {
  text-shadow: none;
  background: #ccc4ba; }

a {
  color: #a87f66; }

a:hover {
  color: #301610;
  text-decoration: none; }

h1 {
  margin-top: 35px;
  text-align: center;
  color: #a87f66;
  text-transform: none;
  font-size: 80px;
  font-family: 'Yanone Kaffeesatz';
  font-weight: 300;
  margin-bottom: 0px; }

h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #706f6f;
  font-size: 40px;
  font-family: 'Yanone Kaffeesatz';
  font-weight: 600; }

h3 {
  font-size: 1.7rem; }

html {
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-feature-settings: "kern"; }

body {
  display: block;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  font-weight: 200;
  color: #302e2c; }
  body a {
    font-weight: 400;
    color: #302e2c; }
  body a:hover {
    color: #a87f66; }
  body .stephanus_braeu img {
    width: 100%;
    margin-top: -50px; }
  body .stephanus_braeu h2 {
    font-size: 80px;
    font-weight: 300;
    margin-top: -150px;
    font-family: "Yanone Kaffeesatz"; }
  body .stephanus_braeu h3 {
    text-align: center;
    margin-top: -50px;
    padding-bottom: 50px;
    font-family: "Yanone Kaffeesatz";
    font-weight: 600; }
  body .tages-special {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 30px; }
    body .tages-special * {
      z-index: 999;
      position: relative; }
    body .tages-special h2 {
      font-family: "Yanone Kaffeesatz";
      font-size: 80px;
      font-weight: 300;
      text-shadow: 1px 1px #ffffff; }
    body .tages-special h3 {
      font-family: "Yanone Kaffeesatz";
      font-size: 40px;
      text-align: center;
      font-weight: 700;
      text-shadow: 1px 1px #ffffff; }
    body .tages-special h4 {
      font-family: "Yanone Kaffeesatz";
      font-size: 250px;
      font-weight: 200;
      text-transform: uppercase;
      color: #fff5e9;
      text-align: center;
      width: 100%;
      position: absolute;
      text-shadow: -1px -1px rgba(29, 29, 29, 0.5); }
    body .tages-special .picture {
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin-top: 150px;
      will-change: transform; }
      body .tages-special .picture picture {
        display: flex;
        justify-content: center; }
        body .tages-special .picture picture img {
          width: 1000px; }
    body .tages-special .container {
      text-align: center; }
  body .container-color-1 {
    background: white;
    background: linear-gradient(180deg, white 0%, #fff5e9 50%) !important; }
  body .container-color-3 {
    background: #ffffff !important; }
  body .nextdate {
    position: absolute;
    top: 2px;
    color: #ccc;
    font-weight: 600;
    font-size: 30px;
    margin-left: 150px;
    mix-blend-mode: color-dodge;
    opacity: 0.5;
    transition: all 0.3s;
    line-height: 35px; }
    body .nextdate a {
      color: #ccc; }
      body .nextdate a img, body .nextdate a svg {
        height: 24px;
        transform: translateY(-3px); }
  body .container-color-2 {
    background: linear-gradient(180deg, #e2d9cf 0%, #fffcf8 72%, #fff5e9 100%) !important; }
    body .container-color-2 .background-repeat {
      position: absolute;
      background-image: url("../images/stoff.png");
      background-position: top;
      background-repeat: repeat-x;
      width: 100%;
      height: calc(100% - 30px);
      opacity: 1;
      z-index: 1;
      top: 0px;
      left: 0px;
      mix-blend-mode: multiply;
      zoom: 0.5; }
  body .logo-brauhaus {
    height: auto;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999; }
    body .logo-brauhaus picture img {
      height: 187px;
      width: auto; }
  body .header {
    max-height: 170px;
    height: 160px;
    overflow: hidden;
    width: 100vw;
    position: fixed;
    top: 0px;
    z-index: 50;
    background: #ffffff; }
    body .header .holz {
      position: absolute;
      bottom: 0px;
      height: 100px;
      border-top: 20px solid #fff5e9;
      width: 100vw; }
      body .header .holz img {
        width: 100%; }
  body .content {
    margin-top: 160px;
    position: relative; }
    body .content .grid-container {
      background: #fff5e9; }
      body .content .grid-container .container {
        position: relative;
        z-index: 3; }
      body .content .grid-container .content--shadow {
        z-index: 4; }
        body .content .grid-container .content--shadow picture {
          position: absolute;
          top: 0px; }
          body .content .grid-container .content--shadow picture img {
            width: 100vw;
            position: absolute; }
      body .content .grid-container .container-fluid .row .col-12, body .content .grid-container .container-sm .row .col-12, body .content .grid-container .container-md .row .col-12, body .content .grid-container .container-lg .row .col-12, body .content .grid-container .container-xl .row .col-12 {
        margin: 0px;
        padding: 0px; }
  body .textblock h2 {
    padding-top: 20px; }
  body .hrh1 {
    background: #b5b5b5;
    border-bottom: 1px solid #fff;
    margin-top: -10px; }
  body header {
    display: flex;
    width: 100vw;
    position: fixed;
    z-index: 99; }
  body main {
    z-index: 50;
    flex: 1;
    width: 100%;
    padding-top: 126px;
    padding-bottom: 0px;
    min-height: calc(100vh - 164px); }
    body main .fa-ul {
      padding: 10px 0px; }
  body .content--shadow-footer {
    position: absolute;
    bottom: 0px;
    width: 100vw; }
    body .content--shadow-footer picture {
      position: absolute;
      width: 100vw; }
      body .content--shadow-footer picture img {
        width: 100%;
        position: absolute;
        transform: translateY(-100%); }
  body footer {
    z-index: 60;
    background: transparent;
    padding: 0px;
    margin: 0px !important;
    position: relative;
    width: 100vw;
    min-height: 50px; }
    body footer .wood {
      width: 100vw;
      padding: 0px;
      position: relative;
      overflow: hidden;
      min-height: 120px; }
      body footer .wood .holz {
        position: absolute;
        width: 100%; }
        body footer .wood .holz img {
          width: 100%;
          filter: brightness(0.7); }
      body footer .wood .row {
        position: relative;
        z-index: 10; }
        body footer .wood .row .footer--menu {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          list-style-type: none;
          font-family: "Yanone Kaffeesatz";
          font-weight: 400;
          font-size: 40px;
          margin: 0px 0px 20px 0px;
          padding: 0px; }
          body footer .wood .row .footer--menu li {
            color: #fff5e9;
            justify-content: center;
            text-align: center;
            flex: 0 0 1px;
            flex-grow: 1;
            text-shadow: 0px 0px -1px #000;
            text-transform: uppercase;
            font-family: "Yanone Kaffeesatz";
            font-weight: 700; }
            body footer .wood .row .footer--menu li ul {
              list-style-type: none;
              margin: 0px;
              padding: 0px;
              color: #ffffff;
              font-weight: 400;
              height: auto;
              line-height: 25px; }
              body footer .wood .row .footer--menu li ul a {
                color: #fff5e9;
                font-weight: 400;
                font-family: "Roboto Condensed";
                font-size: 20px;
                text-shadow: -1px -1px #301610;
                text-transform: none; }
      body footer .wood .hopfen {
        position: absolute;
        right: 0px;
        bottom: 0px;
        z-index: 2; }
        body footer .wood .hopfen picture img {
          max-width: 500px;
          width: 500px; }
    body footer .brown-light {
      background: #a87f66;
      width: 100%;
      padding: 10px; }
    body footer .brown-dark {
      width: 100%;
      position: relative;
      background: #301610;
      text-align: center;
      padding-bottom: 1px; }
      body footer .brown-dark .links {
        font-size: 40px;
        font-family: "Yanone Kaffeesatz";
        font-weight: 600;
        color: #a87f66; }
        body footer .brown-dark .links a {
          color: #a87f66; }
      body footer .brown-dark .copyright {
        font-family: "Yanone Kaffeesatz";
        font-size: 15px;
        font-weight: 400;
        color: #a87f66; }
  body .btn-primary {
    color: #212529; }

.grid-container {
  position: relative; }
  .grid-container .content--shadow {
    position: absolute;
    top: 0px;
    width: 100%; }
  .grid-container .container-fluid-shadow {
    overflow: hidden;
    position: relative;
    z-index: 3; }

.ani-fade-in {
  transform: translate(0px, 0px) !important;
  opacity: 1 !important; }

.socialmediaicons {
  font-size: 25px; }

.dropdown-item.active, .dropdown-item:active {
  background-color: transparent !important; }

.bildmittext-box {
  margin-bottom: 40px;
  margin-top: 20px; }
  .bildmittext-box img {
    max-width: 100%; }

.buttons-modul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px; }
  .buttons-modul a {
    justify-content: center;
    text-align: center;
    transition: color 0.3s; }
  .buttons-modul .btn-modul {
    display: flex;
    justify-content: center; }
    .buttons-modul .btn-modul i {
      font-size: 60px;
      padding: 10px;
      border-radius: 10px;
      background: #a87f66;
      color: #fff;
      margin-bottom: 10px;
      transition: color 0.3s;
      width: 80px;
      height: 80px; }
      .buttons-modul .btn-modul i img, .buttons-modul .btn-modul i svg {
        width: 60px;
        max-height: 60px;
        max-width: 60px;
        transform: translateY(-19px); }
  .buttons-modul a:hover i {
    color: rgba(255, 255, 255, 0.5); }

.modal {
  z-index: 999999;
  display: block; }
  .modal .modal-dialog {
    transform: translateY(-50%);
    max-width: 800px; }
    .modal .modal-dialog .modal-header {
      padding-bottom: 0px;
      padding-top: 0px;
      border: none; }
      .modal .modal-dialog .modal-header .close {
        background: none;
        border: none; }
    .modal .modal-dialog .modal-body {
      max-height: calc(100vh - 240px);
      overflow-y: scroll; }

.nav-btn-color-darkerblue {
  background: linear-gradient(0deg, #00274b 50%, #1e405e 50%); }
  .nav-btn-color-darkerblue .dropdown-menu .dropdown-item {
    color: #00274b !important; }
  .nav-btn-color-darkerblue .dropdown-menu a:hover {
    color: #1e405e !important; }

.nav-btn-color-darkblue {
  background: linear-gradient(0deg, #00315e 50%, #265076 50%); }
  .nav-btn-color-darkblue .dropdown-menu .dropdown-item {
    color: #00315e !important; }
  .nav-btn-color-darkblue .dropdown-menu a:hover {
    color: #265076 !important; }

.nav-btn-color-blue {
  background: linear-gradient(0deg, #0069b1 50%, #267fbd 50%); }
  .nav-btn-color-blue .dropdown-menu .dropdown-item {
    color: #0069b1 !important; }
  .nav-btn-color-blue .dropdown-menu a:hover {
    color: #267fbd !important; }

.nav-btn-color-darkgreen {
  background: linear-gradient(0deg, #00673e 50%, #267e5b 50%); }
  .nav-btn-color-darkgreen .dropdown-menu .dropdown-item {
    color: #00673e !important; }
  .nav-btn-color-darkgreen .dropdown-menu a:hover {
    color: #267e5b !important; }

.nav-btn-color-green {
  background: linear-gradient(0deg, #8ec92d 50%, #9fd14c 50%); }
  .nav-btn-color-green .dropdown-menu .dropdown-item {
    color: #8ec92d !important; }
  .nav-btn-color-green .dropdown-menu a:hover {
    color: #9fd14c !important; }

.nav-btn-color-orange {
  background: linear-gradient(0deg, #ffbe42 50%, #ffc85e 50%); }
  .nav-btn-color-orange .dropdown-menu .dropdown-item {
    color: #ffbe42 !important; }
  .nav-btn-color-orange .dropdown-menu a:hover {
    color: #ffc85e !important; }

.nav-btn-color-yellow {
  background: linear-gradient(0deg, #fedc56 50%, #fee16f 50%); }
  .nav-btn-color-yellow .dropdown-menu .dropdown-item {
    color: #fedc56 !important; }
  .nav-btn-color-yellow .dropdown-menu a:hover {
    color: #fee16f !important; }

@media (max-width: 1199.98px) {
  .news .col-xl-6 .entry-content {
    margin-top: -10px; } }

@media (max-width: 991.98px) {
  .navbar {
    top: 80px;
    box-shadow: 0 -10px 20px 10px #000;
    height: auto; }
    .navbar .navbar-toggler {
      border: none;
      box-shadow: none; }
    .navbar .navbar-nav {
      height: auto;
      font-size: 25px; }
      .navbar .navbar-nav .nav-item {
        min-height: 35px;
        flex: unset;
        flex-direction: column; }
        .navbar .navbar-nav .nav-item a {
          width: 100vw;
          text-align: center;
          justify-content: center; }
        .navbar .navbar-nav .nav-item .dropdown-menu a {
          font-size: 20px; }
    .navbar .navbar-collapse {
      position: fixed;
      top: 80px;
      width: 100vw; }
    .navbar .nextdate {
      display: none; }
  body .content {
    margin-top: 80px; }
  body .teaser article {
    top: 20%;
    padding-left: 20px;
    padding-right: 20px; }
  body .bilder-slider-preview {
    max-width: 100vw; }
  footer .wood .footer--menu {
    flex-direction: column !important;
    display: none !important; }
  footer .wood .logo-footer {
    width: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
  footer .wood .socialmediaicons-footer i {
    font-size: 35px; } }

@media (max-width: 1199.98px) {
  .navbar {
    top: 80px;
    box-shadow: 0 -10px 20px 10px #000;
    height: auto; }
    .navbar .navbar-toggler {
      border: none;
      box-shadow: none; }
    .navbar .navbar-nav {
      height: auto;
      font-size: 25px;
      flex-direction: column; }
      .navbar .navbar-nav .nav-item {
        min-height: 35px;
        flex: unset;
        flex-direction: column; }
        .navbar .navbar-nav .nav-item a {
          width: 100vw;
          text-align: center;
          justify-content: center; }
        .navbar .navbar-nav .nav-item .dropdown-menu a {
          font-size: 20px; }
    .navbar .navbar-collapse {
      position: fixed;
      top: 80px;
      width: 100vw; }
    .navbar .nextdate {
      display: none; }
  body .content {
    margin-top: 80px; }
  body .teaser article {
    top: 20%;
    padding-left: 20px;
    padding-right: 20px; }
  body .bilder-slider-preview {
    max-width: 100vw; }
  footer .wood .footer--menu {
    flex-direction: column !important;
    display: none !important; }
  footer .wood .logo-footer {
    width: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
  footer .wood .socialmediaicons-footer i {
    font-size: 35px; } }

@media (max-width: 1199.98px) {
  body .logo-brauhaus picture img {
    max-height: 90px; }
  body .header {
    bottom: unset;
    top: 0px !important;
    max-height: 80px !important; }
  body .navbar .socialmediaicons {
    position: fixed;
    top: 27px !important; }
    body .navbar .socialmediaicons i {
      color: #fff !important; }
  body .navbar .navbar-toggler {
    top: 35px !important;
    border-color: #fff; }
  body .navbar-light .navbar-toggler {
    color: #fff !important; }
  body .navbar-light .navbar-toggler-icon {
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"); }
  body .bilder--galerie .bilder--slider {
    height: calc(100vw / 16 * 8); } }

@media (max-width: 767.98px) {
  .teaser--halb .teaser {
    max-height: 200px;
    min-height: 180px; }
  body .geschenkkarte {
    width: 100% !important;
    margin-left: 0px !important; }
  body .logo-brauhaus picture img {
    max-height: 80px; }
  body .navbar .navbar-toggler {
    transform: translateY(-10px); }
  body .navbar .header .holz .nextdate {
    font-size: 16px;
    opacity: 0;
    margin-left: 10px; }
  body .navbar .socialmediaicons {
    opacity: 0;
    width: calc(100vw - 70px);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    top: 7px !important; }
    body .navbar .socialmediaicons a i {
      margin: 0px 10px 10px 0px; }
      body .navbar .socialmediaicons a i svg, body .navbar .socialmediaicons a i img {
        height: 35px; }
  body .teaser--main .teaser {
    min-height: 300px;
    max-height: 300px; }
  body .teaser--main .teaser--bg {
    height: 110% !important; }
    body .teaser--main .teaser--bg article {
      padding-left: 10px;
      padding-right: 10px;
      top: 40%; }
      body .teaser--main .teaser--bg article h1 {
        font-size: 40px;
        margin-top: 0px; }
      body .teaser--main .teaser--bg article p {
        font-size: 20px;
        font-weight: 600; }
  body .teaser--main .has-closed .clock i svg, body .teaser--main .has-closed .clock i img {
    height: 40px;
    transform: translate(5px, -9px) !important; }
  body .teaser--shadow {
    box-shadow: 0px -3px 7px rgba(0, 0, 0, 0.5) !important; }
  body .kalendar .monat .container-fluid > div, body .kalendar .monat .container-sm > div, body .kalendar .monat .container-md > div, body .kalendar .monat .container-lg > div, body .kalendar .monat .container-xl > div {
    margin-bottom: 40px !important; }
  body .kontakticons a {
    height: 50px; }
    body .kontakticons a i {
      padding: 0px;
      width: 60px;
      display: flex;
      justify-content: center; }
      body .kontakticons a i img, body .kontakticons a i svg {
        height: 50px; }
    body .kontakticons a .text {
      display: none; }
  body footer .kontakticons {
    margin-bottom: 100px !important; }
    body footer .kontakticons a {
      height: 50px; }
      body footer .kontakticons a i {
        padding: 0px;
        width: 60px;
        display: flex;
        justify-content: center; }
        body footer .kontakticons a i img, body footer .kontakticons a i svg {
          height: 50px; }
      body footer .kontakticons a .text {
        display: none; }
  body footer .wood .hopfen picture img {
    max-width: 270px;
    width: 270px; }
  body .kartenkategorie {
    max-height: 30px;
    overflow: hidden;
    transition: max-height 0.8s; }
    body .kartenkategorie .karten-childrens article {
      margin: 0px;
      padding: 0px; } }

@media (max-width: 575.98px) {
  body .logo-brauhaus picture img {
    max-height: 70px; }
  body .bilder-slider-preview {
    bottom: 0px !important;
    height: 43px !important; }
    body .bilder-slider-preview picture {
      height: 40px; }
      body .bilder-slider-preview picture img {
        border: 1px solid #fff !important; }
  body h1 {
    font-size: 40px; }
  body h2 {
    font-size: 25px; }
  body .navbar {
    top: 80px;
    box-shadow: 0 -10px 20px 10px #000;
    height: auto; }
    body .navbar .navbar-toggler {
      border: none;
      box-shadow: none; }
    body .navbar .navbar-nav {
      height: auto;
      font-size: 25px; }
      body .navbar .navbar-nav .nav-item {
        min-height: 35px;
        flex: unset;
        flex-direction: column; }
        body .navbar .navbar-nav .nav-item a {
          width: 100vw;
          text-align: center;
          justify-content: center; }
        body .navbar .navbar-nav .nav-item .dropdown-menu a {
          font-size: 20px; }
    body .navbar .navbar-collapse {
      position: fixed;
      top: 80px;
      width: 100vw; }
    body .navbar .nextdate {
      display: none; }
  body .content {
    margin-top: 80px; }
  body .has-open {
    width: 280px;
    height: 50px;
    transform: translate(-50%, -50px); }
    body .has-open .clock {
      width: 50px;
      height: 30px; }
      body .has-open .clock i {
        font-size: 30px; }
    body .has-open .text {
      font-size: 20px;
      margin-top: 11px; }
    body .has-open .zeit {
      font-size: 16px;
      width: 80px;
      margin-top: 11px; }
  body .has-closed {
    width: 210px;
    height: 50px;
    transform: translate(-50%, -50px); }
    body .has-closed .clock {
      width: 50px;
      height: 30px; }
      body .has-closed .clock i {
        font-size: 30px; }
    body .has-closed .text-closed {
      font-size: 20px;
      margin-top: 11px; }
    body .has-closed .zeit {
      font-size: 16px;
      width: 80px;
      margin-top: 11px; }
  .tages-special h2 {
    font-size: 30px !important; }
  .tages-special h3 {
    font-size: 25px !important; }
  .tages-special h4 {
    font-size: 85px !important; }
  .tages-special .picture {
    margin-top: 70px !important; }
  footer .wood .footer--menu {
    flex-direction: column !important;
    display: none !important; }
  footer .wood .logo-footer {
    width: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
  footer .wood .socialmediaicons-footer i {
    font-size: 35px; } }
