.stephanus_braeu {
  img {
    width: 100%;
    margin-top: -50px;
  }
  h2 {
    font-size: 80px;
    font-weight: 300;
    margin-top: -150px;
    font-family: "Yanone Kaffeesatz";
  }
  h3 {
    text-align: center;
    margin-top: -50px;
    padding-bottom: 50px;
    font-family: "Yanone Kaffeesatz";
    font-weight: 600;
  }
}