.content--shadow-footer {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  picture {
    position: absolute;
    width: 100vw;
    img {
      width: 100%;
      position: absolute;
      transform: translateY(-100%);
    }
  }
}


footer {
  z-index: 60;
  background: transparent;
  padding: 0px;
  margin: 0px !important;
  position: relative;
  width: 100vw;
  min-height: 50px;
  .wood {
    width: 100vw;
    padding: 0px;
    position: relative;
    overflow: hidden;
    min-height: 120px;
    .holz {
      position: absolute;
      width: 100%;
      img {
        width: 100%;
        filter: brightness(0.7);
      }
    }

    .row {
      position: relative;
      z-index: 10;
      .footer--menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        list-style-type: none;
        font-family: "Yanone Kaffeesatz";
        font-weight: 400;
        font-size: 40px;
        margin: 0px 0px 20px 0px;
        padding: 0px;
        li {
          color: #fff5e9;
          justify-content: center;
          text-align: center;
          flex: 0 0 1px;
          flex-grow: 1;
          text-shadow: 0px 0px -1px #000;
          text-transform: uppercase;
          font-family: "Yanone Kaffeesatz";
          font-weight: 700;
          ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            color: #ffffff;
            font-weight: 400;
            height: auto;
            line-height: 25px;
            a {
              color: #fff5e9;
              font-weight: 400;
              font-family: "Roboto Condensed";
              font-size: 20px;
              text-shadow: -1px -1px #301610;
              text-transform: none;
            }
          }
        }
      }
    }
    .hopfen {
      position: absolute;
      right: 0px;
      bottom: 0px;
      z-index: 2;
      picture {
        img {
          max-width: 500px;
          width: 500px;
        }
      }
    }
  }

  .brown-light {
    background: #a87f66;
    width: 100%;
    padding: 10px;
  }

  .brown-dark {
    width: 100%;
    position: relative;
    background: #301610;
    text-align: center;
    padding-bottom: 1px;

    .links {
      font-size: 40px;
      font-family: "Yanone Kaffeesatz";
      font-weight: 600;
      color: #a87f66;
      a {
        color: #a87f66;
      }
    }
    .copyright {
      font-family: "Yanone Kaffeesatz";
      font-size: 15px;
      font-weight: 400;
      color: #a87f66;
    }
  }
}