//@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Yanone+Kaffeesatz:wght@200;300;400;700&display=swap');
//@import "fonts";


$navbar--bg: #fff;
$navbar--bg-1: #fff;
$navbar--bg-2: #bababa;
$navbar--bg-1-pos: 20%;
$navbar--bg-2-pos: 40%;
$navbar--height: 80px;
$header-height: 160px;

$navbar--font-size: 35px;
$navbar--font-family: "Yanone Kaffeesatz";
$navbar--text-transform: uppercase;
$navbar--color-active: rgba(255,255,255,0.5);
$navbar--color: #ffffff;
$navbar--color-hover: $navbar--color-active;
$navbar--padding: 0px 100px;

$logo--height: 97px;

$navbar--logo-top: 15px;
$navbar--logo-left: 20px;

$body-bg: #ffffff;
$body-color: #666666;

$flag-icon-css-path: '../fonts';
$flag-icon-rect-path: '/';
$flag-icon-square-path: '/';

$footer-color: #ffffff;
$fa-font-path: '../fonts';


// Required
@import "_import";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "fonts";

//$navbar--width: map-get($container-max-widths, 'xl');
$navbar--width: 100%;

$theme-colors: (
  "secondary": $footer-color,
  "primary": #a87f66,
  "primary-dark": #301610,
  "primary-light": #ffffff,
  "primary-light-hover": #60a900,
  "btn-primary-text-hover": #fff,
  "btn-primary-text": #212529,
  "btn-primary-bg": #ffa401,
  "body-bg-hover": #e0e0e0
);

$dropdown-bg: rgba(255,255,255, 0.7);
$dropdown-link-hover-bg: none;
$dropdown-link-color: theme-color('primary-light');

//$headings-font-family: 'Montserrat', $font-family-sans-serif;
//$font-family-base: 'Roboto Slab', $font-family-sans-serif;
$font-family-base: 'Roboto Condensed';
$headings-font-weight: 400;

@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
//@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
//@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
//@import "../node_modules/bootstrap/scss/button-group";
//@import "../node_modules/bootstrap/scss/input-group";
//@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
//@import "../node_modules/bootstrap/scss/card";
//@import "../node_modules/bootstrap/scss/breadcrumb";
//@import "../node_modules/bootstrap/scss/pagination";
//@import "../node_modules/bootstrap/scss/badge";
//@import "../node_modules/bootstrap/scss/jumbotron";
//@import "../node_modules/bootstrap/scss/alert";
//@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
//@import "../node_modules/bootstrap/scss/close";
//@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
//@import "../node_modules/bootstrap/scss/tooltip";
//@import "../node_modules/bootstrap/scss/popover";
//@import "../node_modules/bootstrap/scss/carousel";
//@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";

@import "navs/topnav";

@import "cat-colors";

@import "teaser";
@import "teaser-halb";
@import "karte";
@import "stephans_braeu_flaschen";
@import "bilderslider";
@import "brauhauswoch";
@import "kalendar";
@import "kontakt_icons";
@import "etiketten";


::selection {
  text-shadow: none;
  background: #ccc4ba;
}

a {
  color: theme-color('primary');
}

a:hover {
  color: theme-color('primary-dark');
  text-decoration: none;
}

h1 {
  margin-top: 35px;
  text-align: center;
  color: theme-color('primary');
  text-transform: none;
  font-size: 80px;
  font-family: 'Yanone Kaffeesatz';
  font-weight: 300;
  margin-bottom: 0px;
}

h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #706f6f;
  font-size: 40px;
  font-family: 'Yanone Kaffeesatz';
  font-weight: 600;
}

h3 {
  font-size: 1.7rem;
}

html {
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-feature-settings: "kern";
}

body {
  display: block;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  font-weight: 200;
  color: #302e2c;

  a {
    font-weight: 400;
    color: #302e2c;
  }
  a:hover{
    color: #a87f66;
  }

  @import "stephanus-braeu";
  @import "tages-special";



  .container-color-1 {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,245,233,1) 50%) !important;
  }

  .container-color-3 {
    background: #ffffff !important;
  }

  .nextdate {
    position: absolute;
    top: 2px;
    color: #ccc;
    font-weight: 600;
    font-size: 30px;
    margin-left: 150px;
    mix-blend-mode: color-dodge;
    opacity: 0.5;
    transition: all 0.3s;
    line-height: 35px;
    a {
      color: #ccc;
      img, svg {
        height: 24px;
        transform: translateY(-3px);
      }
    }
  }
  .container-color-2 {
    background: linear-gradient(180deg, rgba(226,217,207,1) 0%, rgba(255,252,248,1) 72%, rgba(255,245,233,1) 100%) !important;

    .background-repeat {
      position: absolute;
      background-image: url("../images/stoff.png");
      background-position: top;
      background-repeat: repeat-x;
      width: 100%;
      height: calc(100% - 30px);
      opacity: 1;
      z-index: 1;
      top: 0px;
      left: 0px;
      mix-blend-mode: multiply;
      zoom: 0.5;
    }
  }

  .logo-brauhaus {
    height: auto;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    picture {
      img {
        height: 187px;
        width: auto;
      }
    }
  }

  .header {
    max-height: 170px;
    height: $header-height;
    overflow: hidden;
    width: 100vw;
    position: fixed;
    top: 0px;
    z-index: 50;
    background: #ffffff;
    //transition: height 0.3s;
    .holz {
      position: absolute;
      bottom: 0px;
      height: 100px;
      border-top: 20px solid #fff5e9;
      width: 100vw;
      img {
        width: 100%;
      }
    }
  }

  .content {
    margin-top: 160px;
    position: relative;
    .grid-container {
      background: #fff5e9;
      .container {
        position: relative;
        z-index: 3;
      }
      .content--shadow {
        z-index: 4;
        picture {
          position: absolute;
          top: 0px;
          img {
            width: 100vw;
            position: absolute;
          }
        }
      }
      .container-fluid {
        .row {
          .col-12 {
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }
  }

  .textblock {
    h2 {
      padding-top: 20px;
    }
  }

  .hrh1 {
    background: #b5b5b5;
    border-bottom: 1px solid #fff;
    margin-top: -10px;
  }

  header {
    display: flex;
    width: 100vw;
    position: fixed;
    z-index: 99;
  }

  main {
    z-index: 50;
    flex: 1;
    width: 100%;
    padding-top: 126px;
    padding-bottom: 0px;
    min-height: calc(100vh - 164px);
    .fa-ul {
      padding: 10px 0px;
    }
  }

  @import "footer";

  .btn-primary {
    background-color: theme-color("btn-primary");
    color: theme-color("btn-primary-text");
  }
}

.grid-container {
  position: relative;
  .content--shadow {
    position: absolute;
    top: 0px;
    width: 100%;
  }
  .container-fluid-shadow {
    overflow: hidden;
    position: relative;
    z-index: 3;
  }
}

.ani-fade-in {
  transform: translate(0px, 0px) !important;
  opacity: 1 !important;
}

.socialmediaicons {
  font-size: 25px;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: transparent !important;
}

.bildmittext-box {
  img {
    max-width: 100%;
  }
  margin-bottom: 40px;
  margin-top: 20px;
}

.buttons-modul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  a {
    justify-content: center;
    text-align: center;
    transition: color 0.3s;
  }
  .btn-modul {
    display: flex;
    justify-content: center;
    i {
      font-size: 60px;
      padding: 10px;
      border-radius: 10px;
      background: theme-color('primary');
      color: #fff;
      margin-bottom: 10px;
      transition: color 0.3s;
      width: 80px;
      height: 80px;
      img,svg {
        width: 60px;
        max-height: 60px;
        max-width: 60px;
        transform: translateY(-19px);
      }
    }
  }

  a:hover {
    i {
      color: rgba(255,255,255,0.5);
    }
  }
}

.modal {
  z-index: 999999;
  display: block;
  .modal-dialog {
    transform: translateY(-50%);
    max-width: 800px;
    .modal-header {
      padding-bottom: 0px;
      padding-top: 0px;
      border: none;
      .close {
        background: none;
        border: none;
      }
    }
    .modal-body {
      max-height: calc(100vh - 240px);
      overflow-y: scroll;
    }
  }
}

@import "nav-colors";



@import "xl";
@import "l";
@import "lg";
@import "md";
@import "sm";
@import "xs";