.nav-btn-color-darkerblue {
  background: linear-gradient(0deg, $cat-darkerblue 50%, $cat-darkerblue-light 50%);
  .dropdown-menu {
    .dropdown-item {
      color: $cat-darkerblue !important;
    }
    a:hover {
      color: $cat-darkerblue-light !important;
    }
  }
}

.nav-btn-color-darkblue {
  background: linear-gradient(0deg, $cat-darkblue 50%, $cat-darkblue-light 50%);
  .dropdown-menu {
    .dropdown-item {
      color: $cat-darkblue !important;
    }
    a:hover {
      color: $cat-darkblue-light !important;
    }
  }
}

.nav-btn-color-blue {
  background: linear-gradient(0deg, $cat-blue 50%, $cat-blue-light 50%);
  .dropdown-menu {
    .dropdown-item {
      color: $cat-blue !important;
    }
    a:hover {
      color: $cat-blue-light !important;
    }
  }
}

.nav-btn-color-darkgreen {
  background: linear-gradient(0deg, $cat-darkgreen 50%, $cat-darkgreen-light 50%);
  .dropdown-menu {
    .dropdown-item {
      color: $cat-darkgreen !important;
    }
    a:hover {
      color: $cat-darkgreen-light !important;
    }
  }
}

.nav-btn-color-green {
  background: linear-gradient(0deg, $cat-green 50%, $cat-green-light 50%);
  .dropdown-menu {
    .dropdown-item {
      color: $cat-green !important;
    }
    a:hover {
      color: $cat-green-light !important;
    }
  }
}

.nav-btn-color-orange {
  background: linear-gradient(0deg, $cat-orange 50%, $cat-orange-light 50%);
  .dropdown-menu {
    .dropdown-item {
      color: $cat-orange !important;
    }
    a:hover {
      color: $cat-orange-light !important;
    }
  }
}

.nav-btn-color-yellow {
  background: linear-gradient(0deg, $cat-yellow 50%, $cat-yellow-light 50%);
  .dropdown-menu {
    .dropdown-item {
      color: $cat-yellow !important;
    }
    a:hover {
      color: $cat-yellow-light !important;
    }
  }
}