@include media-breakpoint-down(sm) {
  .teaser--halb {
    .teaser {
      max-height: 200px;
      min-height: 180px;
    }
  }

  body {
    .geschenkkarte {
      width: 100% !important;
      margin-left: 0px !important;
    }
    .logo-brauhaus {
      picture {
        img {
          max-height: 80px;
        }
      }
    }
    .navbar {
      .navbar-toggler {
        transform: translateY(-10px);
      }
      .header {
        .holz{
          .nextdate {
            font-size: 16px;
            opacity: 0;
            margin-left: 10px;
          }
        }
      }
      .socialmediaicons {
        opacity: 0;
        width: calc(100vw - 70px);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        top: 7px !important;
        a {
          i {
            margin: 0px 10px 10px 0px;
            svg, img {
              height: 35px;
            }
          }
        }
      }
    }
    .teaser--main {
      .teaser {
        min-height: 300px;
        max-height: 300px;
      }

      .teaser--bg {
        height: 110% !important;
        article {
          padding-left: 10px;
          padding-right: 10px;
          top: 40%;

          h1 {
            font-size: 40px;
            margin-top: 0px;
          }

          p {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
      .has-closed {
        .clock {
          i {
            svg, img {
              height: 40px;
              transform: translate(5px,-9px) !important;
            }
          }
        }
      }
    }
    .teaser--shadow {
      box-shadow: 0px -3px 7px rgba(0, 0, 0, .5) !important;
    }

    .kalendar {
      .monat {
        .container-fluid {
          >div {
            margin-bottom: 40px !important;
          }
        }
      }
    }
    .kontakticons {
      a {
        height: 50px;
        i {
          padding: 0px;
          width: 60px;
          display: flex;
          justify-content: center;
          img,svg {
            height: 50px;
          }
        }
        .text {
          display: none;
        }
      }
    }
    footer {
      .kontakticons {
        margin-bottom: 100px !important;
        a {
          height: 50px;
          i {
            padding: 0px;
            width: 60px;
            display: flex;
            justify-content: center;
            img,svg {
              height: 50px;
            }
          }
          .text {
            display: none;
          }
        }
      }
      .wood {
        .hopfen {
          picture {
            img {
              max-width: 270px;
              width: 270px;
            }
          }
        }
      }
    }

    .kartenkategorie {
      max-height: 30px;
      overflow: hidden;
      transition: max-height 0.8s;
      .karten-childrens article {
        margin: 0px;
        padding: 0px;
      }
    }
  }
}