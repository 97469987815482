.bilder--galerie {
  justify-content: center;
  display: flex;
  .bilder--slider {
    width: 100%;
    height: calc(100vw / 16 * 9);
    overflow: hidden;
    position: relative;

    picture {
      opacity: 0;
      transition: opacity 0.8s;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }

    .active {
      opacity: 1;
    }
  }

  .bilder-slider-preview {
    position: absolute;
    bottom: 70px;
    height: 83px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;

    picture {
      position: relative;
      height: 72px;
      width: 120px;
      padding: 5px;
      margin: 0px 10px;
      cursor: pointer;

      img {
        width: calc(100% - 10px);
        position: absolute;
        top: 5px;
        left: 5px;
        opacity: 1;
        transition: opacity 0.8s;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
        border: 3px solid #ffffff;
      }
    }

    .active {
      img {
        opacity: 0.5;
      }
    }
  }
}