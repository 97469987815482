.stephanus--flaschen {
  position: relative;
  height: 750px;
  display: flex;
  justify-content: center;
  margin-top: 50px;

  picture {
    width: 758px;
    height: 750px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  img {
    height: 700px;
    position: absolute;
    will-change: transfrom;
  }


  .flasche-1 {
    z-index: 10;
  }

  .flasche-2 {
    z-index: 9;
  }

  .flasche-3 {
    z-index: 8;
  }

  .flasche-4 {
    z-index: 7;
  }

  .horizon {
    height: unset !important;
    width: 100%;
    position: absolute;
    top: 60%;
    picture {
      width: 100%;
      img {
        width: 100%;
        height: unset;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .stephanus--flaschen {
    height: 350px !important;
    overflow: hidden;
    width: 100vw;
    transform: translateX(-50%);
    left: 50%;

    picture {
      width: 350px;
      height: 345px;
    }
    img {
      height: 350px;
    }
  }
}