.brauhaus-woche {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  .tag {
    width: 100%;
    display: flex;
    flex-direction: column;

    h2 {
      border-bottom: 0px;
      text-align: left;
      padding-left: 600px;
    }

    .inhalt {
      width: 100%;
      display: flex;
      flex-direction: row;

      .picture {
        margin-top: -50px;
        display: flex;

        picture {
          width: 600px;
          img {
            width: 600px;
          }
        }
      }

      .inhalt {
        flex-direction: column;
        .headline {
          font-weight: bold;
          color: $cat-darkgreen;
          font-weight: bold;
        }
      }
    }

  }
}

@include media-breakpoint-down(md) {
  .brauhaus-woche {
    width: 100vw;
    margin-left: -15px;
    .tag {
      margin-bottom: 60px;
      h2 {
        padding-left: 0px !important;
        text-align: center;
        font-size: 30px;
      }
      .inhalt {
        flex-direction: column;
        overflow: hidden;
        .inhalt {
          margin-left: 15px;
          width: calc(100vw - 30px);
        }

        .picture {
          margin-top: 0px !important;
          width: 130% !important;
          picture {
            width: 100%;
            transform: translate3d(-44px, 0px, 0px);
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}